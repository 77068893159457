<template>
  <div v-if="!(pano.isPro&&pano.hideCount)" class="">
    <div style="margin-top:10px;line-height:2rem;font-size:1rem">
      <span v-if="pano.viewCount!==undefined" class="text-shadow" title="观看"><i class="fas fa-eye" /> {{ pano.viewCount }}</span><br>
      <span v-if="pano.praiseCount!==undefined" class="text-shadow" title="点赞" style="pointer-events:auto" @click="PraiseCount"><i class="fas fa-thumbs-up" /> {{ pano.praiseCount }}</span>
    </div>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {
      }
    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
          ViewCount: this.ViewCount,
          PraiseCount: this.PraiseCount,
          ShareCount: this.ShareCount,
        }
      })
    },
    destroyed() {
    },
    methods: {
      getPraiseed() {
        return this.publicData.Praiseed || 0
      },
      setPraiseed(n) {
        this.getFunc('changePublicData')({ Praiseed: n })
      },
      PraiseCount() {
        console.log('PraiseCount')
        if (this.getPraiseed() != 0) {
          return
        }
        this.setPraiseed(1)
        this.$emit('PraiseCount')
        if (this.config.praise) {
          this.getFunc(this.config.praise)().then(() => {
            this.setPraiseed(2)
          }).catch((err) => {
            this.setPraiseed(0)
          })
          return
        }
        this.$axios.post('/Api/Panos/PraiseCount/' + this.pano.guid).then((res) => {
          this.getFunc('updatePano')({
            praiseCount: res.data.praiseCount,
            viewCount: res.data.viewCount,
            shareCount: res.data.shareCount,
          })
          this.setPraiseed(2)
          this.$axios.post('/Api/Comments/Praise/' + this.pano.guid).then((res) => {
            this.getFunc({ target: 'comment', name: 'InsertComment' })(res)
          }).catch((err) => {
          })
        }).catch((err) => {
          this.setPraiseed(0)
        })
      },
      ViewCount() {
        if (this.makeViewCount) {
          this.makeViewCount()
          return
        }
        this.$axios.post('/Api/Panos/ViewCount/' + this.pano.guid).then((res) => {
          this.getFunc('updatePano')({
            praiseCount: res.data.praiseCount,
            viewCount: res.data.viewCount,
            shareCount: res.data.shareCount,
          })
        })
      },
      ShareCount() {
        this.$axios.post('/Api/Panos/ShareCount/' + this.pano.guid).then((res) => {
          this.getFunc('updatePano')({
            praiseCount: res.data.praiseCount,
            viewCount: res.data.viewCount,
            shareCount: res.data.shareCount,
          })
        })
      },
    },
  }
</script>
<style scoped>
</style>
